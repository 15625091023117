<template>
  <v-stepper v-model="e1">
 <v-stepper-header>
   <v-stepper-step step="1" color="secondary text--primary" :complete="e1 > 1">
     {{$t('amount')}}
   </v-stepper-step>
   <v-divider></v-divider>
   <v-stepper-step step="2" color="secondary text--primary" :complete="e1 > 2">
    {{$t('confirmation')}}
   </v-stepper-step>
   <v-divider></v-divider>
   <v-stepper-step color="secondary text--primary" step="3">
    {{$t('thanks')}}
   </v-stepper-step>
 </v-stepper-header>
 <v-stepper-items>
   <v-stepper-content step="1">
     <v-form ref="form" v-model="valid" lazy-validation>
     <LandingDonationAmount ref="donationAmt"></LandingDonationAmount>
     </v-form>
     <v-btn rounded color="secondary" class="primary--text mb-2 " :disabled="!valid" @click="step1Complete">
      <span class="text-capitalize">{{$t('next')}}</span>
      <v-icon right>keyboard_arrow_right</v-icon>
     </v-btn>
   </v-stepper-content>
<v-stepper-content step="2">
 <LandingDonationConfirmation v-on:last-step="step2Complete" ref="confirmDonation"></LandingDonationConfirmation>
     <v-btn rounded color="secondary" class="primary--text mb-2 mr-2" :disabled="$store.getters.getConfirmBtnStatus"  @click="processPayment">
      <span class="text-capitalize">{{$t('confirm')}}</span>
      <v-icon right>thumb_up</v-icon>
     </v-btn>
     <v-btn rounded color="secondary" class=" primary--text mb-2 ml-2" :disabled="$store.getters.getCancelBtnStatus" @click="cancelDonation">
      <span class="text-capitalize">{{$t('cancel')}}</span>
      <v-icon right>cancel</v-icon>
     </v-btn>
   </v-stepper-content>
   <v-stepper-content step="3">
       <LandingDonationThankYou :response="$store.getters.getStripeDonationResponse"></LandingDonationThankYou>
     <v-btn rounded color="secondary" class="primary--text ma-2" @click="moreDonation">
     <v-icon left>favorite</v-icon>
      <span class="text-lowercase">{{$t('another_donation')}}</span>
     </v-btn>
   </v-stepper-content>
 </v-stepper-items>
  </v-stepper>

</template>
<script>

export default {
  name: 'landing-donation',
  data: () => ({
    e1: 1,
    valid: true
  }),
  components: {
    LandingDonationAmount: () => import(/* webpackChunkName: "LandingDonationAmount" */'../components/LandingDonationAmount'),
    LandingDonationConfirmation: () => import(/* webpackChunkName: "LandingDonationConfirmation" */'../components/LandingDonationConfirmation'),
    LandingDonationThankYou: () => import(/* webpackChunkName: "LandingDonationThankYou" */'../components/LandingDonationThankYou')
  },
  methods: {
    step1Complete () {
      // this.$refs.donationAmt.displayValue()
      this.e1 = 2
      this.$refs.form.validate()
      this.$refs.donationAmt.setStoreValues()
    },
    processPayment () {
      this.$refs.confirmDonation.saveTransaction()
    },
    step2Complete () {
      this.e1 = 3
    },
    moreDonation () {
      this.e1 = 1
      this.$store.dispatch('enableCancelAction')
    },
    cancelDonation () {
      this.e1 = 1
    }
  }
}
</script>
<i18n>
{
  "en":{
    "amount":"Amount",
    "confirmation":"Confirmation",
    "receipt":"Receipt",
    "next":"next",
    "confirm":"confirm",
    "cancel":"cancel",
    "thanks":"Thanks",
    "another_donation":"make another donation"
  },
  "fr":{
    "amount":"Montant",
    "confirmation":"Confirmation",
    "receipt":"Reçu",
    "next":"suivant",
    "confirm":"confirmer",
    "cancel":"annuler",
    "thanks":"Merci",
    "another_donation":"faire un autre don"
  },
  "es":{
    "amount":"Importe",
    "confirmation":"Confirmación",
    "receipt":"Recibo",
    "next":"próximo",
    "confirm":"confirmar",
    "cancel":"anular",
    "thanks":"Gracias",
    "another_donation":"hacer otra donación"
  }
}
</i18n>
